html, body {
    padding: 0;
    margin: 0;
    font-family: serif;
    background-color: #ccc
}

.app {
    font-family: Consolas, monospace;
    /*height: 100vh;*/
    display: flex;
    flex-direction: column;
    min-height: 0;
    position: relative;
}

.content {
    flex: auto;
    min-height: 0;
    text-align: center;
    background-color: #ccc;
    max-width: min(100%, 1200px);
    width: 100%;

    align-self: center;
}
