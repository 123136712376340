.new-sentence-preview-section {
    margin-top: 20px;
}

.new-sentence-sentence-options {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 16px;
    position: relative;
    top: 2px;
}

.new-sentence-sentence-section {
    /* No margin needed */
}

.new-sentence-selection-section {
    margin-top: 16px;
    position: relative;
}

.new-sentence-preview-wrapper {
    padding: 1rem;
    background-color: cornsilk;
    border-radius: 16px;
    border: 2px solid burlywood;
    z-index: 6;
}

.new-sentence-preview-wrapper-active {
    position: relative;
    background-color: cornsilk;
    padding: 1rem;
    border-radius: 16px;
    border: 2px solid burlywood;
}

.new-sentence-preview-wrapper-active::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.new-sentence-preview-font-color-radio {
    display: flex;
    justify-content: center;
    position: relative;
    gap: 0;
    top: -2px;
}

.new-sentence-preview-font-content,
.new-sentence-preview-color-content {
    margin-top: 8px;
}

.new-sentence-preview-background-content {
    margin-top: 8px;
}

/* Override ant-design Radio button styles for all radio buttons in the new sentence section */
/* Fix for Czcionka/Kolory buttons */
.new-sentence-preview-font-color-radio .ant-radio-button-wrapper-checked {
    color: #fff;
    background: #1677ff;
    border-color: #1677ff;
}

.new-sentence-preview-font-color-radio .ant-radio-button-wrapper {
    border-radius: 0;
    margin: 0;
}

.new-sentence-preview-font-color-radio .ant-radio-button-wrapper:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.new-sentence-preview-font-color-radio .ant-radio-button-wrapper:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

/* Fix for all other radio buttons in color manager inside the new sentence preview */
.new-sentence-preview-wrapper .ant-radio-button-wrapper-checked {
    color: #fff;
    background: #1677ff;
    border-color: #1677ff;
}

.new-sentence-preview-wrapper .ant-radio-button-wrapper {
    border-radius: 6px;
    margin: 0 4px;
}

@media (min-width: 768px) {
    .new-sentence-preview-options {
        flex-direction: row;
        justify-content: center;
        gap: 20px;
    }
} 