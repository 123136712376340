.sentence-preview-word-wrapper {
    position: relative;
    user-select: none;
    flex: 0 0 auto;
}

.sentence-preview-word {
    /*flex-wrap: wrap;*/
    /*display: flex;*/
}

.sentence-preview-syllabe {
    /*display: flex;*/
}

.sentence-preview-letter {

}

.sentence-preview-word-select-mode {
    /*padding: 2px;*/
    border-radius: 4px;
    /*border: 2px dashed #a0afff;*/
    outline: 2px dashed #a0afff;
    outline-offset: -1px;
    cursor: pointer;
}





.sentence-preview-syllabe-select-mode {
    /*padding: 2px;*/
    border-radius: 4px;
    /*border: 2px dashed #a0afff;*/
    outline: 2px dashed #a0afff;
    outline-offset: -1px;
    cursor: pointer;
}





.sentence-preview-letter-select-mode {
    border-radius: 4px;
    /*border: 2px dashed #a0afff;*/
    outline: 2px dashed #a0afff;
    outline-offset: -1px;
    cursor: pointer;
}

@media (hover: hover) {
    .sentence-preview-word-select-mode:hover {
        background-color: #b6b6ff;
    }
}

@media (hover: hover) {
    .sentence-preview-syllabe-select-mode:hover {
        background-color: #b6b6ff;
    }
}

@media (hover: hover) {
    .sentence-preview-letter-select-mode:hover {
        background-color: #b6b6ff;
    }
}

.sentence-preview-word-selected {
    background-color: #d1d1ff;
    border-width: 2px;
    border-color: #4d4dff;
    /*color: red;*/
    /*border-color: #e08b1e;*/
}

.sentence-preview-syllabe-selected {
    background-color: #d1d1ff;
    /*border-width: 2px;*/
    /*border-color: #4d4dff;*/
}

.sentence-preview-letter-selected {
    background-color: #d1d1ff;
    border-width: 2px;
    border-color: #4d4dff;
}
.sentence-preview-letter-split-not-last {
    cursor: pointer;

}

.sentence-preview-dash-splits {
    cursor: pointer;
}

.sentence-preview-dash-splits:hover {
    cursor: pointer;
}

@media (hover: hover) {
    .sentence-preview-dash-splits:hover {
        color: #555;
    }
}