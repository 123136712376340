.section-wrapper {
    background-color: cornsilk;
    border-radius: 16px;
    position: relative;
    border: 2px solid burlywood;
    text-align: center;
    margin-top: 24px;
}

.section-wrapper-with-title {
    margin-top: 24px;
}

/* Single title styling */
.section-title {
    position: relative;
    display: inline-flex;
    font-size: 1.5rem;
    top: -24px;
    background-color: cornsilk;
    border-radius: 16px;
    border: 2px solid burlywood;
    padding: 0.5rem 1rem;
    cursor: pointer;
    margin: 0 0.5rem;
    transition: all 0.2s ease-in-out;
    height: 1.5em;
    align-items: center;
    justify-content: center;
    min-width: 120px;
    width: auto;
    white-space: nowrap;
}

/* Style for titles in the titles container (multiple titles) */
.section-titles-container .section-title {
    position: relative;
    border-radius: 0;
    font-size: 1.2rem;
    padding: 0.4rem 0.9rem;
    margin: 0;
    border: 2px solid burlywood;
    background-color: cornsilk;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    height: 1.4em;
    line-height: normal;
    top: -20px;
}

/* First button in the group */
.section-titles-container .section-title:first-child {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
}

/* Last button in the group */
.section-titles-container .section-title:last-child {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
}

/* Remove double borders between buttons */
.section-titles-container .section-title:not(:first-child) {
    border-left: none;
}

/* Active title in multi-title section */
.section-title-active {
    font-size: 1.5rem !important;
    font-weight: normal;
    color: inherit;
    background-color: cornsilk !important;
    border: 2px solid burlywood !important;
    border-radius: 0 !important;
    padding: 0.5rem 1rem !important;
    z-index: 3 !important;
    position: relative;
    top: -24px !important;
    margin: 0 !important;
    height: 1.5em !important;
    line-height: normal !important;
}

/* Apply border radius only to first and last active buttons */
.section-titles-container .section-title:first-child.section-title-active {
    border-top-left-radius: 16px !important;
    border-bottom-left-radius: 16px !important;
}

.section-titles-container .section-title:last-child.section-title-active {
    border-top-right-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
}

.section-title-inactive {
    font-size: 1.2rem;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.6);
    background-color: cornsilk; /* Lighter shade, but still distinguishable from cornsilk */
    border: 2px dashed burlywood;
    z-index: 1;
    border-radius: 0;
    padding: 0.4rem 0.9rem;
    position: relative;
    top: -20px;
    margin: 0;
    height: 1.4em;
    line-height: normal;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    min-width: 100px;
    width: auto;
    white-space: nowrap;
    transition: all 0.2s ease-in-out;
}

/* More specific selector to override the base .section-title background */
.section-titles-container .section-title.section-title-inactive {
    background-color: cornsilk; /* Ensure this matches the color above */
    border: 2px dashed burlywood;
}

.section-title-inactive:hover {
    color: rgba(0, 0, 0, 0.9);
    border-color: #d4a76a;
    background-color: #fff8e6;
    opacity: 1;
    transform: none;
    box-shadow: none;
    transition: all 0.2s ease-in-out;
}

/* More specific selector for hover state to ensure styles are applied without !important */
.section-titles-container .section-title.section-title-inactive:hover {
    border-color: #d4a76a;
    background-color: #fff8e6;
}

.section-content-transition {
    transition: all 0.3s ease-in-out;
    animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0.5;
        transform: translateY(5px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.section-example {
    display: none;
}

.section-example h3 {
    display: none;
}

.section-example h4 {
    color: #444;
    margin-top: 0;
    margin-bottom: 10px;
}

.section-example p {
    margin-bottom: 10px;
    line-height: 1.4;
}

.section-content-normal {
    padding-bottom: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    min-height: 50px;
}

.section-content-no-title-normal {
    padding-top: 1.5rem;
}

.section-content-small {
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    min-height: 30px;
}

.section-content-no-title-small {
    padding-top: 1rem;
}

/* Container for multiple titles */
.section-titles-container {
    display: flex;
    justify-content: center;
    position: relative;
    top: 0;
    margin-bottom: 5px;
    height: 60px;
    align-items: flex-start;
    gap: 0;
}

/* Style for adjacent titles to create button group effect - no longer needed */
.section-titles-container .section-title:not(:first-child) {
    /* No special styling needed */
}

.section-titles-container .section-title:first-child {
    /* No special styling needed */
}

.section-titles-container .section-title:last-child {
    /* No special styling needed */
}