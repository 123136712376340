.sentence-split-radio-group-label {
    font-weight: bolder;

}

.sentence-split-radio-group-inactive {
    color: lightgray;
}

.sentence-split-label {
    display: flex;
    gap: 4px;
}

.active-syllabe-1 {
    color: red;
}

.active-syllabe-2 {
    color: deepskyblue;
}

.active-dash {
    color: orange;
} 