.new-sentence-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.new-sentence-submit {
    display: flex;
    justify-content: center;
    margin-top: 16px;
}

/* Reuse the same styling as CurrentSentence for the sentence options */
.new-sentence-sentence-options {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 16px;
    position: relative;
    top: 2px;
}

