.radio-group {
    display: flex;
    font-size: 16px;
    font-family: 'Consolas, monospace', monospace;
    position: relative;
    z-index: 0;

}

.radio-group-option {
    border: 2px solid burlywood;
    padding: 4px 12px;
    cursor: pointer;
    position: relative;
}

.radio-group-option-selected {
    border-color: #1677ff;
    z-index: 1;
}

.radio-group-option-first {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.radio-group-option-last {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}