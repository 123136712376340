.sentence-case-radio-group-label {
    font-weight: bolder;
}

.sentence-case-radio-group-inactive {
    color: lightgray;
}

.label {
    letter-spacing: 2px;
}

.active-letter-1 {
    color: red;
    font-family: 'Consolas, monospace', monospace
}

.active-letter-2 {
    color: orange;
}

.active-letter-3 {
    color: deepskyblue;
} 