.sentence-preview {
    display: inline-flex;


    gap: 10px;
    font-size: 38px;
}

.sentence-preview-wrap {
    flex-wrap: wrap;
    line-break: anywhere;
}

.sentence-preview-no-wrap {
    /*overflow: hidden;*/
}