.color-picker {
    /*position: relative;*/
}

.color-picker-swatch {
    border: 2px solid black;
    border-radius: 8px;
    width: 50px;
    height: 30px;
    position: relative;
}


.color-picker-swatch-active {
    outline: 5px solid orange;
}

.color-picker-popover {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 3;
    top: 100%; /* Position at the bottom of the swatch */
    margin-top: 10px; /* Add 10px gap below the swatch */
    left: 50%;
    transform: translateX(-50%); /* Center the popover horizontally */
}

/* Fixed positioning for the color picker when a container ref is provided */
.color-picker-popover-fixed {
    position: absolute;
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Ensure proper centering */
}

.color-picker-remove {
    background-color: cornsilk;
    border: 2px solid #353535;
    border-radius: 8px;
    margin-top: 10px;
    /*position: relative;*/
    /*top: 10px;*/
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    cursor: pointer;
}