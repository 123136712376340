.current-sentence-sentence-options {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 16px;
    position: relative;
    top: 2px;
}
.current-sentence-sentence-section {
    /*margin-top: 16px;*/
}

.current-sentence-selection-section {
    margin-top: 16px;
    position: relative;
}

.current-sentence-selection-radio {
    position: relative;
    top: 2px;
}

.current-sentence-sentence-preview-wrapper {
    padding: 1rem;
    background-color: cornsilk;
    border-radius: 16px;
    border: 2px solid burlywood;
    z-index: 6;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    background-size: cover;
    background-position: center;
}

.current-sentence-sentence-preview-wrapper-active {
    position: relative;
    background-color: cornsilk;
    padding: 1rem;
    border-radius: 16px;
    border: 2px solid burlywood;
}

.current-sentence-sentence-font-color-radio {
    display: flex;
    justify-content: center;
    position: relative;
    gap: 16px;
    top: -2px;
}

.current-sentence-sentence-font-color-radio-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    border: 2px solid burlywood;
    border-radius: 6px;
    /*border-bottom-left-radius: 6px;*/
    /*border-bottom-right-radius: 6px;*/
    transition: 0.5s;

    color: rgba(0, 0, 0, 0.88);
    font-size: 16px;
    line-height: 28px;
    cursor: pointer;
}

.current-sentence-sentence-font-color-radio-button-active {
    /*font-size: 24px;*/
    font-weight: normal;
    border-bottom-color: #1677ff;
    border-left-color: #1677ff;
    border-right-color: #1677ff;
}

.current-sentence-select-font-button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    gap: 6px;
}

.current-sentence-select-font-button-icon {
    transform: rotate(90deg);
}

.current-sentence-select-font-button-text {
}

.current-sentence-select-font-content {
    margin-top: 8px;
}

.current-sentence-select-color-content {
    margin-top: 8px;
}

.current-sentence-select-background-content {
    margin-top: 8px;
} 