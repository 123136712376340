.color-manager-wrapper {
}

.color-manager-overlay {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: #060606d6;
    z-index: 4;
    top: 0;
    left: 0
}

/* New container for both button groups */
.color-manager-buttons-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 24px;
    margin-bottom: 16px;
}

.color-manager-mode-options {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
    flex-wrap: wrap;
    /* Remove margin-bottom since it's handled by the container */
}

.color-manager-options {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
    flex-wrap: wrap;
}

.color-manager-list {
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
}

.color-manager-add {
    border: 2px dashed lightgray;
    border-radius: 8px;
    width: 50px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-self: center;
    cursor: pointer;
    background-color: cornsilk;
}

.color-manager-random-button {
    margin-top: 16px;
    margin-bottom: 16px;
}

/* Media query for smaller screens if needed */
@media (max-width: 600px) {
    .color-manager-buttons-container {
        flex-direction: column;
        gap: 16px;
    }
}