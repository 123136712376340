.text-outline {
    text-shadow:
        -1px -1px 0 #333,
        0   -1px 0 #333,
        1px -1px 0 #333,
        1px  0   0 #333,
        1px  1px 0 #333,
        0    1px 0 #333,
        -1px  1px 0 #333,
        -1px  0   0 #333;
}