
        @font-face {
            font-family: 'BordersDivide';
            src: url('fonts/BordersDivide.ttf') format('truetype');
            font-weight: normal;
            font-style: normal;
        }


        @font-face {
            font-family: 'BreeSerif-Regular';
            src: url('fonts/BreeSerif-Regular.ttf') format('truetype');
            font-weight: normal;
            font-style: normal;
        }


        @font-face {
            font-family: 'FiraSans-Regular';
            src: url('fonts/FiraSans-Regular.otf') format('opentype');
            font-weight: normal;
            font-style: normal;
        }


        @font-face {
            font-family: 'JandaSafeandSound';
            src: url('fonts/JandaSafeandSound.ttf') format('truetype');
            font-weight: normal;
            font-style: normal;
        }


        @font-face {
            font-family: 'PlaywritePL-VariableFont_wght';
            src: url('fonts/PlaywritePL-VariableFont_wght.ttf') format('truetype');
            font-weight: normal;
            font-style: normal;
        }


        @font-face {
            font-family: 'PoetsenOne-Regular';
            src: url('fonts/PoetsenOne-Regular.ttf') format('truetype');
            font-weight: normal;
            font-style: normal;
        }


        @font-face {
            font-family: 'Quando-Regular';
            src: url('fonts/Quando-Regular.ttf') format('truetype');
            font-weight: normal;
            font-style: normal;
        }


        @font-face {
            font-family: 'rimouski_sb';
            src: url('fonts/rimouski_sb.ttf') format('truetype');
            font-weight: normal;
            font-style: normal;
        }

