.background-display {
  width: 100%;
  margin: 20px 0;
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.background-display h3 {
  margin-top: 0;
  color: #333;
}

.background-display h4 {
  margin-top: 15px;
  margin-bottom: 10px;
  color: #555;
}

.selected-background {
  margin: 20px 0;
  text-align: center;
}

.background-preview {
  max-width: 100%;
  max-height: 300px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.background-list {
  width: 100%;
  margin: 20px 0;
}

.background-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
}

.nav-button {
  width: 40px;
  height: 40px;
  background: transparent;
  border: 2px solid burlywood;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}

.nav-button:hover:not(:disabled) {
  /*border-color: #333;*/
  color: #333;
  background: #e0d19b;
}

.nav-button:disabled {
  border-color: #d9d9d9;
  cursor: not-allowed;
  opacity: 0.5;
}

.nav-arrow {
  font-size: 18px;
  color: inherit;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) {
  .background-thumbnails {
    display: grid;
    grid-template-columns: repeat(var(--items-per-row), 1fr);
    grid-template-rows: repeat(var(--rows), 1fr);
    gap: 10px;
    flex: 1;
    padding: 10px;
  }
}

@media (max-width: 768px) {
  .background-thumbnails {
    display: grid;
    grid-template-columns: repeat(var(--rows), 1fr);
    grid-template-rows: repeat(var(--items-per-row), 1fr);
    gap: 4px;
    flex: 1;
    /* padding: 4px; */
  }
}


.background-thumbnail {
  position: relative;
  aspect-ratio: 16/9;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.background-thumbnail:hover {
  transform: scale(1.05);
}

.background-thumbnail.selected {
  box-shadow: 0 0 0 4px rgba(0, 123, 255, 0.6), 0 0 10px rgb(0, 207, 255), 0 0 20px rgba(0, 123, 255, 0.57), 0 0 30px rgba(0, 123, 255, 0.54), 0 0 40px rgba(0, 123, 255, 0.47);
  z-index: 6;
}

.background-thumbnail.empty {
  background-color: rgba(0, 0, 0, 0.05);
}

.background-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.background-name {
  font-size: 12px;
  padding: 5px;
  display: block;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.background-display.loading,
.background-display.error {
  padding: 20px;
  text-align: center;
  color: #666;
}

.background-display.error {
  color: #e74c3c;
}

.background-info {
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid #eee;
  font-size: 14px;
  color: #666;
}

.background-info code {
  background-color: #f0f0f0;
  padding: 2px 5px;
  border-radius: 3px;
  font-family: monospace;
}

.more-backgrounds {
  margin-top: 10px;
  font-size: 13px;
  color: #888;
  font-style: italic;
} 