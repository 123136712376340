/* AppHeader styles */
.app-header {
  background-color: #fff;
  border-bottom: 1px solid #e8e8e8;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  box-shadow: 0 1px 4px rgba(0,0,0,0.05);
}

/* Left side styles */
.app-header__left {
  display: flex;
  align-items: center;
}

.app-header__logo {
  font-weight: bold;
  font-size: 18px;
  margin-right: 24px;
  color: #1890ff;
}

.app-header__connection {
  display: flex;
  align-items: center;
  gap: 4px;
}

.app-header__menu-button span {
  margin-left: 4px;
}

/* Right side styles */
.app-header__right {
  display: flex;
  align-items: center;
}

/* User dropdown styles */
.app-header__user {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.app-header__user:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.app-header__username {
  margin-left: 8px;
  font-weight: 500;
}

/* Auth buttons styles */
.app-header__auth-buttons .ant-btn {
  display: flex;
  align-items: center;
}

.app-header__auth-buttons .ant-btn .anticon {
  font-size: 16px;
  margin-right: 6px;
  display: flex;
  align-items: center;
}

.app-header__auth-buttons .ant-btn-primary {
  background-color: #1890ff;
}

.app-header__auth-buttons .ant-btn-primary:hover {
  background-color: #40a9ff;
}

/* Add icon spacing for better alignment */
.anticon {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* State debug display */
.app-header__state-debug {
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
  width: 100%;
  overflow-x: auto;
  max-height: 150px;
  font-size: 11px;
  z-index: 1000;
  background-color: #fff;
}

.app-header__state-debug pre {
  color: rgb(255, 57, 57);
  line-height: 1.2;
  margin: 0;
  padding: 8px 16px;
  background-color: rgba(0,0,0,0.03);
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.15);
} 